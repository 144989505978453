/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LossOfProductionMetricCreateRequest,
    LossOfProductionMetricCreateRequestFromJSON,
    LossOfProductionMetricCreateRequestToJSON,
    LossOfProductionMetricRepresentation,
    LossOfProductionMetricRepresentationFromJSON,
    LossOfProductionMetricRepresentationToJSON,
    LossOfProductionMetricUpdateRequest,
    LossOfProductionMetricUpdateRequestFromJSON,
    LossOfProductionMetricUpdateRequestToJSON,
    LossOfProductionMetricVariantCreateRequest,
    LossOfProductionMetricVariantCreateRequestFromJSON,
    LossOfProductionMetricVariantCreateRequestToJSON,
    LossOfProductionMetricVariantRepresentation,
    LossOfProductionMetricVariantRepresentationFromJSON,
    LossOfProductionMetricVariantRepresentationToJSON,
    LossOfProductionMetricVariantUpdateRequest,
    LossOfProductionMetricVariantUpdateRequestFromJSON,
    LossOfProductionMetricVariantUpdateRequestToJSON,
} from '../models';

export interface CreateLossOfProductionMetricRequest {
    lossOfProductionMetricCreateRequest?: LossOfProductionMetricCreateRequest;
}

export interface CreateLossOfProductionMetricVariantRequest {
    metricKey: string;
    lossOfProductionMetricVariantCreateRequest?: LossOfProductionMetricVariantCreateRequest;
}

export interface DeleteLossOfProductionMetricRequest {
    metricKey: string;
}

export interface DeleteLossOfProductionMetricVariantRequest {
    metricKey: string;
    variantKey: string;
}

export interface UpdateLossOfProductionMetricRequest {
    metricKey: string;
    lossOfProductionMetricUpdateRequest?: LossOfProductionMetricUpdateRequest;
}

export interface UpdateLossOfProductionMetricVariantRequest {
    metricKey: string;
    variantKey: string;
    lossOfProductionMetricVariantUpdateRequest?: LossOfProductionMetricVariantUpdateRequest;
}

/**
 * 
 */
export class LossOfProductionMetricApi extends runtime.BaseAPI {

    /**
     * Create a new loss-of-production metric
     */
    async createLossOfProductionMetricRaw(requestParameters: CreateLossOfProductionMetricRequest): Promise<runtime.ApiResponse<LossOfProductionMetricRepresentation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/loss-of-production-metrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LossOfProductionMetricCreateRequestToJSON(requestParameters.lossOfProductionMetricCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LossOfProductionMetricRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new loss-of-production metric
     */
    async createLossOfProductionMetric(lossOfProductionMetricCreateRequest?: LossOfProductionMetricCreateRequest): Promise<LossOfProductionMetricRepresentation> {
        const response = await this.createLossOfProductionMetricRaw({ lossOfProductionMetricCreateRequest: lossOfProductionMetricCreateRequest });
        return await response.value();
    }

    /**
     * Create a new variant for this loss-of-production metric
     */
    async createLossOfProductionMetricVariantRaw(requestParameters: CreateLossOfProductionMetricVariantRequest): Promise<runtime.ApiResponse<LossOfProductionMetricVariantRepresentation>> {
        if (requestParameters.metricKey === null || requestParameters.metricKey === undefined) {
            throw new runtime.RequiredError('metricKey','Required parameter requestParameters.metricKey was null or undefined when calling createLossOfProductionMetricVariant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/loss-of-production-metrics/{metricKey}/variants`.replace(`{${"metricKey"}}`, encodeURIComponent(String(requestParameters.metricKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LossOfProductionMetricVariantCreateRequestToJSON(requestParameters.lossOfProductionMetricVariantCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LossOfProductionMetricVariantRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new variant for this loss-of-production metric
     */
    async createLossOfProductionMetricVariant(metricKey: string, lossOfProductionMetricVariantCreateRequest?: LossOfProductionMetricVariantCreateRequest): Promise<LossOfProductionMetricVariantRepresentation> {
        const response = await this.createLossOfProductionMetricVariantRaw({ metricKey: metricKey, lossOfProductionMetricVariantCreateRequest: lossOfProductionMetricVariantCreateRequest });
        return await response.value();
    }

    /**
     * Delete a loss-of-production metric
     */
    async deleteLossOfProductionMetricRaw(requestParameters: DeleteLossOfProductionMetricRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metricKey === null || requestParameters.metricKey === undefined) {
            throw new runtime.RequiredError('metricKey','Required parameter requestParameters.metricKey was null or undefined when calling deleteLossOfProductionMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/loss-of-production-metrics/{metricKey}`.replace(`{${"metricKey"}}`, encodeURIComponent(String(requestParameters.metricKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a loss-of-production metric
     */
    async deleteLossOfProductionMetric(metricKey: string): Promise<void> {
        await this.deleteLossOfProductionMetricRaw({ metricKey: metricKey });
    }

    /**
     * Delete a loss-of-production metric variant
     */
    async deleteLossOfProductionMetricVariantRaw(requestParameters: DeleteLossOfProductionMetricVariantRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metricKey === null || requestParameters.metricKey === undefined) {
            throw new runtime.RequiredError('metricKey','Required parameter requestParameters.metricKey was null or undefined when calling deleteLossOfProductionMetricVariant.');
        }

        if (requestParameters.variantKey === null || requestParameters.variantKey === undefined) {
            throw new runtime.RequiredError('variantKey','Required parameter requestParameters.variantKey was null or undefined when calling deleteLossOfProductionMetricVariant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/loss-of-production-metrics/{metricKey}/variants/{variantKey}`.replace(`{${"metricKey"}}`, encodeURIComponent(String(requestParameters.metricKey))).replace(`{${"variantKey"}}`, encodeURIComponent(String(requestParameters.variantKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a loss-of-production metric variant
     */
    async deleteLossOfProductionMetricVariant(metricKey: string, variantKey: string): Promise<void> {
        await this.deleteLossOfProductionMetricVariantRaw({ metricKey: metricKey, variantKey: variantKey });
    }

    /**
     * Return all loss-of-production metrics
     */
    async getLossOfProductionMetricsRaw(): Promise<runtime.ApiResponse<Array<LossOfProductionMetricRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/loss-of-production-metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LossOfProductionMetricRepresentationFromJSON));
    }

    /**
     * Return all loss-of-production metrics
     */
    async getLossOfProductionMetrics(): Promise<Array<LossOfProductionMetricRepresentation>> {
        const response = await this.getLossOfProductionMetricsRaw();
        return await response.value();
    }

    /**
     * Update a loss-of-production metric
     */
    async updateLossOfProductionMetricRaw(requestParameters: UpdateLossOfProductionMetricRequest): Promise<runtime.ApiResponse<LossOfProductionMetricRepresentation>> {
        if (requestParameters.metricKey === null || requestParameters.metricKey === undefined) {
            throw new runtime.RequiredError('metricKey','Required parameter requestParameters.metricKey was null or undefined when calling updateLossOfProductionMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/loss-of-production-metrics/{metricKey}`.replace(`{${"metricKey"}}`, encodeURIComponent(String(requestParameters.metricKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LossOfProductionMetricUpdateRequestToJSON(requestParameters.lossOfProductionMetricUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LossOfProductionMetricRepresentationFromJSON(jsonValue));
    }

    /**
     * Update a loss-of-production metric
     */
    async updateLossOfProductionMetric(metricKey: string, lossOfProductionMetricUpdateRequest?: LossOfProductionMetricUpdateRequest): Promise<LossOfProductionMetricRepresentation> {
        const response = await this.updateLossOfProductionMetricRaw({ metricKey: metricKey, lossOfProductionMetricUpdateRequest: lossOfProductionMetricUpdateRequest });
        return await response.value();
    }

    /**
     * Update a loss-of-production metric variant
     */
    async updateLossOfProductionMetricVariantRaw(requestParameters: UpdateLossOfProductionMetricVariantRequest): Promise<runtime.ApiResponse<LossOfProductionMetricVariantRepresentation>> {
        if (requestParameters.metricKey === null || requestParameters.metricKey === undefined) {
            throw new runtime.RequiredError('metricKey','Required parameter requestParameters.metricKey was null or undefined when calling updateLossOfProductionMetricVariant.');
        }

        if (requestParameters.variantKey === null || requestParameters.variantKey === undefined) {
            throw new runtime.RequiredError('variantKey','Required parameter requestParameters.variantKey was null or undefined when calling updateLossOfProductionMetricVariant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/loss-of-production-metrics/{metricKey}/variants/{variantKey}`.replace(`{${"metricKey"}}`, encodeURIComponent(String(requestParameters.metricKey))).replace(`{${"variantKey"}}`, encodeURIComponent(String(requestParameters.variantKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LossOfProductionMetricVariantUpdateRequestToJSON(requestParameters.lossOfProductionMetricVariantUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LossOfProductionMetricVariantRepresentationFromJSON(jsonValue));
    }

    /**
     * Update a loss-of-production metric variant
     */
    async updateLossOfProductionMetricVariant(metricKey: string, variantKey: string, lossOfProductionMetricVariantUpdateRequest?: LossOfProductionMetricVariantUpdateRequest): Promise<LossOfProductionMetricVariantRepresentation> {
        const response = await this.updateLossOfProductionMetricVariantRaw({ metricKey: metricKey, variantKey: variantKey, lossOfProductionMetricVariantUpdateRequest: lossOfProductionMetricVariantUpdateRequest });
        return await response.value();
    }

}
