import type { LossOfProductionMetricsRepository, LocalizationsRepository } from '@/modules/ctx-admin-loss-of-production/adapter';
import type {
    LossOfProductionMetric,
    LossOfProductionMetricVariant,
} from '@/modules/ctx-admin-loss-of-production/types';
import { i18n } from '@/plugins/i18n';
import NotificationService from '@/assets/js/services/NotificationService';

export class MetricsService {

    private readonly metricsRepository: LossOfProductionMetricsRepository;
    private readonly localizationRepository: LocalizationsRepository;

    constructor(params: {
        metricsRepository: LossOfProductionMetricsRepository,
        localizationRepository: LocalizationsRepository,
    }) {
        this.metricsRepository = params.metricsRepository;
        this.localizationRepository = params.localizationRepository;
    }

    public onChange(callback: () => Promise<void>): void {
        this.metricsRepository.onLossOfProductionMetricsChange(() => callback());
        this.localizationRepository.onLanguageLoaded(() => callback());
    }

    public async getLossOfProductionMetricByKey(key: string): Promise<LossOfProductionMetric|undefined> {
        const metrics = await this.getLossOfProductionMetrics();
        return metrics.find((it) => it.key === key);
    }

    public async getLossOfProductionMetrics(): Promise<LossOfProductionMetric[]> {
        const metrics = await this.metricsRepository.getLossOfProductionMetrics();
        metrics.forEach((metric) => {
            // sort variants by name
            metric.variants = metric.variants.sort((a, b) => {
                if (a.isGlobalDefault) return -1;
                if (b.isGlobalDefault) return 1;
                return a.name.localeCompare(b.name);
            });
        });
        return metrics.sort((a, b) => a.name[i18n.locale].localeCompare(b.name[i18n.locale]));
    }

    public async createLossOfProductionMetric(metric: LossOfProductionMetric): Promise<LossOfProductionMetric> {
        const errors = await this.validateLossOfProductionMetric(metric);
        if (Object.keys(errors).length > 0) {
            throw new Error('Validation failed');
        }
        try {
            return this.metricsRepository.createLossOfProductionMetric(metric);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async deleteLossOfProductionMetric(metricKey: string): Promise<void> {
        try {
            await this.metricsRepository.deleteLossOfProductionMetric(metricKey);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async updateLossOfProductionMetric(metric: LossOfProductionMetric): Promise<LossOfProductionMetric> {
        const errors = await this.validateLossOfProductionMetric(metric);
        if (Object.keys(errors).length > 0) {
            throw new Error('Validation failed');
        }
        try {
            return this.metricsRepository.updateLossOfProductionMetric(metric.key, metric);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async createLossOfProductionMetricVariant(metric: LossOfProductionMetric, variant: LossOfProductionMetricVariant): Promise<LossOfProductionMetric> {
        const errors = await this.validateLossOfProductionMetricVariant(metric, variant);
        if (Object.keys(errors).length > 0) {
            throw new Error('Validation failed');
        }
        try {
            return this.metricsRepository.createLossOfProductionMetricVariant(metric.key, variant);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async updateLossOfProductionMetricVariant(metric: LossOfProductionMetric, variant: LossOfProductionMetricVariant): Promise<LossOfProductionMetric> {
        const errors = await this.validateLossOfProductionMetricVariant(metric, variant);
        if (Object.keys(errors).length > 0) {
            throw new Error('Validation failed');
        }
        try {
            return this.metricsRepository.updateLossOfProductionMetricVariant(metric.key, variant.key, variant);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async deleteLossOfProductionMetricVariant(metricKey: string, variantKey: string): Promise<void> {
        await this.metricsRepository.deleteLossOfProductionMetricVariant(metricKey, variantKey);
    }

    public async validateLossOfProductionMetric(metric: LossOfProductionMetric): Promise<{ [key: string]: string[] }> {
        const errors: { [key: string]: string[] } = {};

        // validate global variant
        const globalVariants = metric.variants.filter((v) => v.isGlobalDefault);
        if (globalVariants.length > 1) {
            errors.variants = [i18n.t('ctx-admin-loss-of-production.error.multiple-global-variants').toString()];
        } else if (globalVariants.length < 1) {
            errors.variants = [i18n.t('ctx-admin-loss-of-production.error.no-global-variant').toString()];
        } else if (globalVariants[0].generatorStatusKeys.length === 0) {
            errors.variants = [i18n.t('ctx-admin-loss-of-production.error.min-status').toString()];
        }

        // validate metric name
        const allMetrics = await this.metricsRepository.getLossOfProductionMetrics();
        const translations = Object.entries(metric.name).map((kv) => ({ locale: kv[0], value: kv[1] }));
        translations.forEach((translation) => {
            const nameErrors = errors[`name.${translation.locale}`] || [];
            // validate missing translations
            if (translation.value.trim().length === 0) {
                nameErrors.push(i18n.t('ctx-admin-loss-of-production.error.missing-translation').toString());
            }
            if (translation.value.trim().length > 255) {
                nameErrors.push(i18n.t('ctx-admin-loss-of-production.error.translation-too-long').toString());
            }
            // validate duplicate names
            // TODO should we also check other metrics?
            if (allMetrics.find((it) => it.key !== metric.key && it.name[translation.locale] === translation.value)) {
                nameErrors.push(i18n.t('ctx-admin-loss-of-production.error.duplicate-translation').toString());
            }
            if (nameErrors.length > 0) {
                errors[`name.${translation.locale}`] = nameErrors;
            }
        });
        return errors;
    }

    public async validateLossOfProductionMetricVariant(metric: LossOfProductionMetric, variant: LossOfProductionMetricVariant): Promise<{ [key: string]: string[] }> {
        const errors: { [key: string]: string[] } = {};

        // validate name
        const otherVariants = metric.variants.filter((v) => v.key !== variant.key);
        const nameErrors = errors.name || [];
        // validate missing translations
        if (variant.name.trim().length === 0) {
            nameErrors.push(i18n.t('ctx-admin-loss-of-production.error.missing-translation').toString());
        }
        if (variant.name.trim().length > 255) {
            nameErrors.push(i18n.t('ctx-admin-loss-of-production.error.translation-too-long').toString());
        }
        // validate duplicate names
        if (otherVariants.find((it) => it.key !== variant.key && it.name === variant.name)) {
            nameErrors.push(i18n.t('ctx-admin-loss-of-production.error.duplicate-variant').toString());
        }
        if (nameErrors.length > 0) {
            errors.name = nameErrors;
        }
        if (!variant.isGlobalDefault && variant.generatorKeys.length === 0) {
            errors.generatorKeys = [i18n.t('ctx-admin-loss-of-production.error.min-generators').toString()];
        }
        if (variant.generatorStatusKeys.length === 0) {
            errors.generatorStatusKeys = [i18n.t('ctx-admin-loss-of-production.error.min-status').toString()];
        }
        return errors;
    }

    public newMetric(): LossOfProductionMetric {
        return {
            key: '',
            licenseFeatures: [],
            name: {
                de: '',
                en: '',
            },
            variants: [{
                key: '',
                name: 'Default',
                isGlobalDefault: true,
                generatorKeys: [],
                generatorStatusKeys: [],
            }],
        };
    }

    public newMetricVariant(metric: LossOfProductionMetric): LossOfProductionMetricVariant {
        const globalVariant = metric.variants.find((variant) => variant.isGlobalDefault);
        return {
            key: '',
            name: '',
            isGlobalDefault: false,
            generatorKeys: [],
            generatorStatusKeys: globalVariant?.generatorStatusKeys || [],
        };
    }
}
